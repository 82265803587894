@import 'styles/variables.scss';

.confirmation-modal .modal-content {
    width: 430px;
    background: $color_4;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 24px;
}

.confirmation-modal .confirmation-modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.confirmation-danger .confirmation-modal-title {
    color: $color_9;
}

.confirmation-primary .confirmation-modal-title,
.confirmation-saving .confirmation-modal-title {
    color: $color_8;
}

.confirmation-modal-close {
    cursor: pointer;
    width: 20px;
}
