@import 'bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import 'styles/variables.scss';

:root {
	--primary-color: #17a2b8;
	--dark-color: #343a40;
	--light-color: #f4f4f4;
	--danger-color: #dc3545;
	--success-color: #28a745;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: $font-family_1;
	font-size: 1rem;
	line-height: 1.6;
	background-color: #fff;
	color: $color_1;
}

a {
	color: $color_2;
	text-decoration: none !important;
}

ul {
	list-style: none;
}

img {
	width: 100%;
}

.container {
	max-width: 1100px;
	margin: auto;
	overflow: hidden;
	padding: 0 2rem;
	margin-top: 6rem;
	margin-bottom: 3rem;
	#scrollableDiv::-webkit-scrollbar {
		display:none;
	}
}

.x-large {
	font-size: 4rem;
	line-height: 1.2;
	margin-bottom: 1rem;
}

.large {
	font-size: 3rem;
	line-height: 1.2;
	margin-bottom: 1rem;
}

.lead {
	font-size: 1.5rem;
	margin-bottom: 1rem;
}

.text-center {
	text-align: center;
}

.text-primary {
	color: $color_2;
}

.text-dark {
	color: $color_3;
}

.p {
	padding: 0.5rem;
}

.p-1 {
	padding: 1rem;
}

.p-2 {
	padding: 2rem;
}

.p-3 {
	padding: 3rem;
}

.py {
	padding: 0.5rem 0;
}

.py-1 {
	padding: 1rem 0;
}

.py-2 {
	padding: 2rem 0;
}

.py-3 {
	padding: 3rem 0;
}

.m {
	margin: 0.5rem;
}

.m-1 {
	margin: 1rem;
}

.m-2 {
	margin: 2rem;
}

.m-3 {
	margin: 3rem;
}

.my {
	margin: 0.5rem 0;
}

.my-1 {
	margin: 1rem 0;
}

.my-2 {
	margin: 2rem 0;
}

.my-3 {
	margin: 3rem 0;
}

.btn {
	display: inline-block;
	background: var(--light-color);
	color: $color_1;
	padding: 0.4rem 1.3rem;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	margin-right: 0.5rem;
	transition: opacity 0.2s ease-in;
	outline: none;

	&:hover {
		opacity: 0.8;
	}
}

.badge {
	font-size: 0.8rem;
	padding: 0.1rem;
	text-align: center;
	margin: 0.3rem;
	background: var(--light-color);
	color: $color_1;
}

.alert {
	padding: 0.8rem;
	margin: 1rem 0;
	opacity: 0.9;
	background: var(--light-color);
	color: $color_1;
}

.btn-primary {
	background: var(--primary-color);
	color: $color_4;
}

.bg-primary {
	background: var(--primary-color);
	color: $color_4;
}

.badge-primary {
	background: var(--primary-color);
	color: $color_4;
}

.alert-primary {
	background: var(--primary-color);
	color: $color_4;
}

.btn-light {
	background: var(--light-color);
	color: $color_1;
}

.bg-light {
	background: var(--light-color);
	color: $color_1;
	border: #ccc solid 1px;
}

.badge-light {
	background: var(--light-color);
	color: $color_1;
	border: #ccc solid 1px;
}

.alert-light {
	background: var(--light-color);
	color: $color_1;
}

.btn-dark {
	background: var(--dark-color);
	color: $color_4;
}

.bg-dark {
	background: var(--dark-color);
	color: $color_4;
}

.badge-dark {
	background: var(--dark-color);
	color: $color_4;
}

.alert-dark {
	background: var(--dark-color);
	color: $color_4;
}

.btn-danger {
	background: var(--danger-color);
	color: $color_4;
}

.bg-danger {
	background: var(--danger-color);
	color: $color_4;
}

.badge-danger {
	background: var(--danger-color);
	color: $color_4;
}

.alert-danger {
	background: var(--danger-color);
	color: $color_4;
}

.btn-success {
	background: var(--success-color);
	color: $color_4;
}

.bg-success {
	background: var(--success-color);
	color: $color_4;
}

.badge-success {
	background: var(--success-color);
	color: $color_4;
}

.alert-success {
	background: var(--success-color);
	color: $color_4;
}

.btn-white {
	background: #fff;
	color: $color_1;
	border: #ccc solid 1px;
}

.bg-white {
	background: #fff;
	color: $color_1;
	border: #ccc solid 1px;
}

.badge-white {
	background: #fff;
	color: $color_1;
	border: #ccc solid 1px;
}

.alert-white {
	background: #fff;
	color: $color_1;
	border: #ccc solid 1px;
}

.round-img {
	border-radius: 50%;
}

.line {
	height: 1px;
	background: #ccc;
	margin: 1.5rem 0;
}

.dark-overlay {
	background-color: rgba(0, 0, 0, 0.7);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.form {
	.form-group {
		margin: 1.2rem 0;
	}

	.form-text {
		display: block;
		margin-top: 0.3rem;
		color: $color_5;
	}

	input[type='text'] {
		display: block;
		width: 100%;
		padding: 0.4rem;
		font-size: 1.2rem;
		border: 1px solid #ccc;
	}

	input[type='email'] {
		display: block;
		width: 100%;
		padding: 0.4rem;
		font-size: 1.2rem;
		border: 1px solid #ccc;
	}

	input[type='password'] {
		display: block;
		width: 100%;
		padding: 0.4rem;
		font-size: 1.2rem;
		border: 1px solid #ccc;
	}

	input[type='date'] {
		display: block;
		width: 100%;
		padding: 0.4rem;
		font-size: 1.2rem;
		border: 1px solid #ccc;
	}

	select {
		display: block;
		width: 100%;
		padding: 0.4rem;
		font-size: 1.2rem;
		border: 1px solid #ccc;
	}

	textarea {
		display: block;
		width: 100%;
		padding: 0.4rem;
		font-size: 1.2rem;
		border: 1px solid #ccc;
	}

	input[type='submit'] {
		font: inherit;
	}

	.social-input {
		display: flex;

		i {
			padding: 0.5rem;
			width: 4rem;
		}

		i.fa-twitter {
			color: $color_6;
		}

		i.fa-facebook {
			color: $color_7;
		}

		i.fa-instagram {
			color: $color_8;
		}

		i.fa-youtube {
			color: $color_9;
		}

		i.fa-linkedin {
			color: $color_10;
		}
	}

	.text_danger {
		color: #FF0000;
		font-size: 80%;
	}
}

button {
	font: inherit;
}

.table {
	th {
		padding: 1rem;
		text-align: left;
		background: var(--light-color);
	}

	td {
		padding: 1rem;
		text-align: left;
	}
}

.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.7rem 2rem;
	position: fixed;
	z-index: 1;
	width: 100%;
	top: 0;
	border-bottom: solid 1px var(--primary-color);
	opacity: 0.9;

	ul {
		display: flex;

		.logout-btn {
			background: none !important;
			color: #fff;
			border: none;

			&:hover {
				color: $color_2;
			}
		}
	}

	a {
		color: $color_4;
		padding: 0.45rem;
		margin: 0 0.25rem;
		text-decoration: none;
		
		&:hover {
			color: $color_2;
		}
	}

	.welcome {
		span {
			margin-right: 0.6rem;
		}
	}
}

.landing {
	position: relative;
	background: url('../assets/images/showcase.jpg') no-repeat center center/cover;
	height: 100vh;
}

.landing-inner {
	color: $color_4;
	height: 100%;
	width: 80%;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.profile {
	display: grid;
	grid-template-columns: 2fr 4fr 2fr;
	align-items: center;
	grid-gap: 2rem;
	padding: 1rem;
	line-height: 1.8;
	margin-bottom: 1rem;
}

.profile-grid {
	display: grid;
	grid-template-areas: 'top top' 'about about' 'exp edu' 'github github';
	grid-gap: 1rem;
}

.profile-top {
	grid-area: top;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	img {
		width: 250px;
	}

	.icons {
		a {
			color: $color_4;
			margin: 0 0.3rem;

			&:hover {
				color: $color_3;
			}
		}
	}
}

.profile-about {
	grid-area: about;
	text-align: center;

	.skills {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
}

.profile-exp {
	grid-area: exp;

	h2 {
		margin-bottom: 1rem;
	}

	>div {
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		border-bottom: #ccc 1px dotted;

		&:last-child {
			border: 0;
		}
	}

	p {
		margin: 0.5rem 0;
	}
}

.profile-edu {
	grid-area: edu;

	h2 {
		margin-bottom: 1rem;
	}

	>div {
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		border-bottom: #ccc 1px dotted;

		&:last-child {
			border: 0;
		}
	}

	p {
		margin: 0.5rem 0;
	}
}

.profile-github {
	grid-area: github;

	.repo {
		display: flex;

		>div {
			&:first-child {
				flex: 7;
				flex-basis: 70%;
			}
		}
	}

	>div {
		&:last-child {
			flex: 3;
			flex-basis: 20%;
		}
	}
}

.post-form {
	.post-form-header {
		background: var(--primary-color);
		padding: 0.5rem;
	}
}

.post {
	display: grid;
	grid-template-columns: 1fr 4fr;
	grid-gap: 2rem;
	align-items: center;

	>div {
		&:first-child {
			text-align: center;
		}
	}

	img {
		width: 100px;
	}

	.comment-count {
		background: var(--light-color);
		color: $color_2;
		padding: 0.1rem 0.2rem;
		border-radius: 5px;
		font-size: 0.8rem;
	}

	.post-date {
		color: $color_11;
		font-size: 0.8rem;
		margin-bottom: 0.5rem;
	}
}

.grid-box {
	height: 500px;
}

@media (max-width: 700px) {
	.container {
		margin-top: 8rem;
	}

	.hide-sm {
		display: none;
	}

	.x-large {
		font-size: 3rem;
	}

	.large {
		font-size: 2rem;
	}

	.lead {
		font-size: 1rem;
	}

	.navbar {
		display: block;
		text-align: center;

		ul {
			text-align: center;
			justify-content: center;
		}

		h1 {
			margin-bottom: 1rem;
		}

		.welcome {
			display: none;
		}
	}

	.profile {
		grid-template-columns: 1fr;
		text-align: center;

		ul {
			display: none;
		}

		img {
			width: 200px;
			margin: auto;
		}
	}

	.profile-top {
		img {
			width: 200px;
			margin: auto;
		}
	}

	.profile-grid {
		grid-template-areas: 'top' 'about' 'exp' 'edu' 'github';
	}

	.profile-about {
		.skills {
			flex-direction: column;
		}
	}

	.dash-buttons {
		a {
			display: block;
			width: 100%;
			margin-bottom: 0.2rem;
		}
	}

	.post {
		grid-template-columns: 1fr;

		a {
			padding: 0.3rem 0.4rem;
		}

		button {
			padding: 0.3rem 0.4rem;
		}
	}
}