$color_1: #333;
$color_2: var(--primary-color);
$color_3: var(--dark-color);
$color_4: #fff;
$color_5: #888;
$color_6: #38a1f3;
$color_7: #3b5998;
$color_8: #3f729b;
$color_9: #c4302b;
$color_10: #0077b5;
$color_11: #aaa;
$color_12: #a1a6b6;
$color_13: #162149;
$color_14: #dcecff;
$font-family_1: 'Raleway', sans-serif;
$border-1-solid: '1 px solid';
