.card-inner {
	display: flex;
	justify-content: space-between;
}

.card-title {
	color: rgb(140, 140, 140);
	font-size: 16px;
}

.value {
	font-family: "Roboto";
	font-weight: bold;
	font-size: 30px;
}